<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2022-06-29 16:14:29
 * @LastEditTime: 2022-06-30 10:45:02
-->
<template>
	<div class="upLoadAisle">
		<div class="upLoadAisle-content">
			<div class="common-row">
				<div class="common-row-item">
					<span class="common-row-label">活动ID</span>
					<el-input class="common-row-ipt" placeholder="请输入活动ID" v-model="upLoadAisleData.activityId" />
				</div>
			</div>
			<div class="common-row" style="margin-top: 20px;">
				<el-button @click="upsideOpen" type="primary">开启上传通道</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		openUpside
	} from "@/api/manhour";
	export default {
		data() {
			return {
				upLoadAisleData: {}
			};
		},
		methods: {
			upsideOpen() {
				if(!this.upLoadAisleData.activityId) {
					this.$message.error("请输入活动ID~")
					return
				}
				openUpside(this.upLoadAisleData).then((res) => {
					if(res.code == 0) {
						this.$message.success("上传通道开启成功~")
					}
				})
			}
		},
	};
</script>

<style lang="scss" scoped>
	/deep/.el-input__inner {
		height: 36px;
		border-radius: 0px 2px 2px 0px;
		border: 0;
	}

	.upLoadAisle {
		.upLoadAisle-content {
			padding: 20px 20px;

			.common-row-ipt {
				width: 260px;
				height: 36px;
				line-height: 36px;
				border: 1px solid #EDF1F7;
				border-left: 0;
			}
		}
	}
</style>
